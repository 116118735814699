import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Icons
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";
import {
  setAudioTitle,
  setAudioSource,
  setAudioRef,
  setSelectedCard,
  toggleIsPlaying,
} from "../store/reducers/mediaReducer";

export const MusicPlayer = () => {
  const dispatch = useDispatch();
  const { audioTitle, audioSource, isPlaying, selectedCard, mixes } =
    useSelector((state: any) => state.media);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // States
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [volumeControl, setVolumeControl] = useState(50);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("ended", handleEnded);
    }
    return () => {
      if (audioRef?.current) {
        audioRef.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("ended", handleEnded);
      }
    };
  }, [audioSource]);

  useEffect(() => {
    if (audioRef?.current) {
      if (isPlaying) {
        audioRef?.current.play();
      } else {
        audioRef?.current.pause();
      }
    }
  }, [isPlaying, audioSource]);

  const handleLoadedMetadata = () => {
    setDuration(audioRef?.current ? audioRef?.current.duration : 0);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef?.current ? audioRef?.current.currentTime : 0);
  };

  const handleEnded = () => {
    if (selectedCard !== null && mixes.length > selectedCard + 1) {
      const nextCardIndex = selectedCard + 1;
      const nextMix = mixes[nextCardIndex];
      const { title, audio } = nextMix;
      dispatch(setSelectedCard(nextCardIndex));
      dispatch(setAudioTitle(title));
      dispatch(setAudioSource(audio.url));
      dispatch(setAudioRef(audioRef));
    } else {
      dispatch(toggleIsPlaying());
    }
  };

  const togglePlayback = () => {
    dispatch(toggleIsPlaying());
  };

  // const handleSkipForward = () => {
  //   if (selectedCard !== null && mixes.length > selectedCard + 1) {
  //     const nextCardIndex = selectedCard + 1;
  //     const nextMix = mixes[nextCardIndex];
  //     const { title, audio } = nextMix;
  //     dispatch(setSelectedCard(nextCardIndex));
  //     dispatch(setAudioTitle(title));
  //     dispatch(setAudioSource(audio.url));
  //     dispatch(setAudioRef(audioRef));
  //   }
  // };

  // const handleSkipBackward = () => {
  //   if (selectedCard !== null && selectedCard > 0) {
  //     const prevCardIndex = selectedCard - 1;
  //     const prevMix = mixes[prevCardIndex];
  //     const { title, audio } = prevMix;
  //     dispatch(setSelectedCard(prevCardIndex));
  //     dispatch(setAudioTitle(title));
  //     dispatch(setAudioSource(audio.url));
  //     dispatch(setAudioRef(audioRef));
  //   }
  // };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current && !isSeeking) {
      const seekTime = parseFloat(e.target.value);
      audioRef.current.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const setVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolumeControl(newVolume);

    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100;
    }
  };

  return (
    <div className="music-player-container">
      <audio className="audio-player" ref={audioRef} src={audioSource}></audio>
      <div className="title">
        <p>{audioTitle ? audioTitle : "Welcome"}</p>
      </div>

      <div className="buttons">
        {isPlaying ? (
          <BsFillPauseFill className="play-pause" onClick={togglePlayback} />
        ) : (
          <BsFillPlayFill className="play-pause" onClick={togglePlayback} />
        )}
      </div>

      <div className="progress">
        <div className="time-duration">
          <p>{formatTime(currentTime)}</p>
        </div>

        <input
          className="progress-control"
          onChange={handleSeek}
          min={0}
          max={duration}
          type="range"
          value={currentTime}
        />

        <div className="time-duration">
          <p>{formatTime(duration)}</p>
        </div>
      </div>

      <div className="volume">
        <p>VOL</p>
        <input
          className="volume-control"
          type="range"
          onChange={setVolume}
          min={0}
        />
      </div>
    </div>
  );
};
